import React, { useCallback, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './styles/Chatinput.css';

const ChatInput = ({ userInput = '', handleInputChange, handleSendClick }) => {
  const textareaRef = useRef(null);

  const handleInputKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendClick({ type: 'text', payload: userInput });
    }
  }, [handleSendClick, userInput]);

  const onSendClick = () => {
    handleSendClick({ type: 'text', payload: userInput });
  };

  // Function to resize textarea based on content
  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height to scrollHeight
  };

  // Trigger resize on input change
  useEffect(() => {
    resizeTextarea();
  }, [userInput]);

  return (
    <div className="chat-input">
      <div className="input-container">
        <textarea
          ref={textareaRef}
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          className="chatbot-input"
          placeholder="Chat with ADAM..."
          rows={1} // Initial row value
          style={{ overflow: 'hidden', height: '10px' }} // Smaller initial height
        />
        <button
          type="button"
          onClick={onSendClick}
          className="chatbot-send-button"
          disabled={!userInput.trim()}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
